import Flicking from '@egjs/react-flicking';
import { useEffect, useRef, useState } from 'react';
import Button from '../atoms/Button';
import ArrowButton from '../molecules/ArrowButton';
import React from 'react';

interface EventCardsSectionProps {
  heading: string;
  body: string;
  cards: Array<{
    icon: {
      altText: string;
      localFile: {
        url: string;
      };
    };
    label: string;
  }>;
  footerHeading: string;
  footerBody: string;
  brochures: Array<{
    city: string;
    fileUrl?: string;
  }>;
}

const EventCardsSection = ({
  heading,
  body,
  cards,
  footerHeading,
  footerBody,
  brochures,
}: EventCardsSectionProps) => {
  const ref = useRef<Flicking>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null);

  const resetScrollInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (!ref.current) {
      return;
    }

    const interval = setInterval(() => {
      ref.current?.next().catch(() => void 0);
    }, 2500);

    setIntervalId(interval);
  };

  const next = async () => {
    ref.current?.next().catch(() => void 0);
    resetScrollInterval();
  };

  const prev = async () => {
    ref.current?.prev().catch(() => void 0);
    resetScrollInterval();
  };

  useEffect(() => {
    resetScrollInterval();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="py-16 text-center lg:py-24 text-navy-blue">
      <div className="container">
        <h1 className="mb-4 u-h2">{heading}</h1>
        <p className="max-w-2xl mx-auto mb-16 u-p">{body}</p>
      </div>
      <div className="relative mb-24">
        <ArrowButton
          variant="left"
          id="prev-card-btn"
          ariaLabel="Previous card"
          className="absolute z-30 -mt-6 left-6 lg:left-18 top-1/2"
          color="alto"
          onClick={prev}
        />
        <ArrowButton
          variant="right"
          id="next-card-btn"
          ariaLabel="Next card"
          className="absolute z-30 -mt-6 right-6 lg:right-18 top-1/2"
          color="alto"
          onClick={next}
        />

        <Flicking
          renderOnlyVisible={true}
          autoInit
          circular
          interruptable={false}
          ref={ref}
        >
          {cards.map((card) => (
            <div
              key={card.label}
              className="bg-linen rounded-[10px] w-96 h-40 flex flex-col items-center justify-center px-8 mx-4"
            >
              {/* <img
                src={card.icon.localFile.url}
                alt={card.icon.altText}
                className="w-auto h-40 mb-8"
              /> */}
              <div className="u-h3 text-navy-blue">{card.label}</div>
            </div>
          ))}
        </Flicking>
      </div>
      <div className="container">
        <div className="flex flex-col items-center max-w-2xl mx-auto">
          <h2 className="mb-4 u-h2">{footerHeading}</h2>
          <p className="mb-8 u-p">{footerBody}</p>
        </div>

        <div className="flex flex-wrap items-center justify-center mx-auto">
          {brochures
            .filter((brochure) => brochure.fileUrl)
            .map((brochure) => (
              <Button
                to={brochure.fileUrl}
                variant="outline"
                key={brochure.city}
                className="mx-4 my-4"
              >
                {brochure.city}
              </Button>
            ))}
        </div>
      </div>
    </section>
  );
};

export default EventCardsSection;
