import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import MoreThanADonutShopImage from '../../images/more-than-a-donut-shop.png';
import Button from '../atoms/Button';
import Image from '../atoms/Image';
import FeatureBgInvertedImg from './feature-bg-inverted.svg';
import FeatureBgImg from './feature-bg.svg';

interface FeatureSectionProps {
  inverted?: boolean;
  heading: string;
  body: string;
  image: {
    localFile: any;
    altText: string;
  };
  buttonLabel: string;
  buttonUrl: string;
}

const FeatureSection = ({
  inverted,
  heading,
  body,
  image: { localFile, altText },
  buttonLabel,
  buttonUrl,
}: FeatureSectionProps) => (
  <section
    className={`relative py-20 overflow-hidden lg:py-40 ${inverted && 'mb-28'}`}
  >
    <div
      className={`absolute inset-0 bg-white bg-top bg-cover -z-1`}
      style={{
        backgroundImage: `url(${
          inverted ? FeatureBgInvertedImg : FeatureBgImg
        })`,
      }}
    />
    <div className="container">
      <div className="relative flex flex-col items-center lg:flex-row bg-linen">
        <img
          src={MoreThanADonutShopImage}
          alt="More than a donut shop"
          className="absolute z-20 h-auto -ml-[86px] w-28 lg:w-40 -mt-14 lg:-mt-28 lg:-ml-28 top-full left-full animate-spin-slow"
        />
        <div className="flex items-center lg:w-1/2">
          <Image
            image={getImage(localFile)}
            alt={altText}
            className="w-full h-auto"
          />
        </div>
        <div className="flex flex-col justify-center px-8 py-8 xl:px-28 lg:py-0 lg:w-1/2">
          <h2 className="u-h2">{heading}</h2>
          <div
            className="mb-6 u-p"
            dangerouslySetInnerHTML={{ __html: body }}
          />

          <div>
            <Button to={buttonUrl}>{buttonLabel}</Button>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FeatureSection;
