import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface CityDropdownInputProps {
  name: string;
  label: string;
  placeholder: string;
}

const CityDropdownInput = ({
  name,
  label,
  placeholder,
}: CityDropdownInputProps) => {
  const {
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
        }
      }
    }
  `);
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  return (
    <>
      <label
        htmlFor={name}
        className={`u-label ${touched && error && 'u-label--error'}`}
      >
        {label}
      </label>
      <div className="relative">
        <select
          id={name}
          name={name}
          className={`w-full u-input h-10.5 ${
            touched && error && 'u-input--error'
          }`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {cities.map(({ title: city }) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="8"
          fill="none"
          viewBox="0 0 14 8"
          className="absolute z-20 w-auto h-2 -mt-1 pointer-events-none top-1/2 right-4"
        >
          <path
            fill="#091F40"
            d="M0 1.408C0 1.046.148.698.427.42a1.549 1.549 0 012.092 0L7 4.634 11.477.407a1.549 1.549 0 012.092 0 1.347 1.347 0 010 1.974L8.045 7.597C7.765 7.86 7.397 8 6.998 8c-.397 0-.766-.153-1.046-.403L.427 2.395A1.408 1.408 0 010 1.408z"
          ></path>
        </svg>
      </div>
      <AnimatePresence>
        {touched && error && (
          <motion.div
            className="text-red-accessibility pt-0.5 mt-2"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CityDropdownInput;
