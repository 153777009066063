import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const BgDonut1 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="282"
    height="381"
    fill="none"
    viewBox="0 0 282 381"
    {...props}
  >
    <path
      fill="#DFC2C2"
      d="M100.316 166.765a36.536 36.536 0 017.528 3.803c17.405 11.74 5.071 30.863-1.925 44.645-.93 1.85-3.778 6.445-4.497 7.842-1.366 1.37-2.934 2.816-4 3.852a39.407 39.407 0 01-3.318 2.073c-1.518-.285-1.122-.124 0 0l-.473.262c-1.916.101-4.181.249-5.25.363-1.721-.286-5.09-.736-5.634-.864a14.292 14.292 0 00-.931-.527c-1.23-.678-2.491-1.349-3.729-2.058-.497-.545-3.205-2.722-3.683-3.178-1.894-1.828-3.8-3.637-5.593-5.566-.229-.324-.629-.922-1.355-1.933-1.998-2.774-3.823-5.618-5.629-8.513-.84-1.376-3.288-6.302-3.459-6.435a106.457 106.457 0 01-4.488-13.962c.014-.285.019-.614.014-1.051-.008-1.922-.052-4.07-.222-6.018-.661-7.485 5.624-12.646 12.013-14.625a51.265 51.265 0 012.855-.796c-.887.089-1.918.164-3.24.194 1.185-.015 2.361-.138 3.552-.264 2.412-.541 2.917-.451 1.675-.203 2.112-.27 4.218-.57 6.311-.648a62.533 62.533 0 0123.478 3.607zm72.486-142.596C136.479 7.191 108.822-3.206 67.084 1.423c-45.92 5.08-79.31 27.154-111.86 57.72-36.922 34.703-64.87 76.734-65.412 123.509A216.612 216.612 0 00-84.272 286.73c23.703 44.174 60.663 65.811 110.444 80.4 46.99 13.791 101.218 20.94 144.922-1.708 37.918-19.585 69.415-51.009 85.486-90.926 1.522-3.813 2.875-7.682 4.125-11.591a199.966 199.966 0 0016.816-126.462c-9.866-49.056-54.568-88.775-104.712-112.244"
    ></path>
  </motion.svg>
);

export default BgDonut1;
