import Flicking from '@egjs/react-flicking';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import Image from '../atoms/Image';
import ArrowButton from '../molecules/ArrowButton';

interface LocationGalleryProps {
  images: Array<{ altText: string; localFile: any }>;
  align?: 'prev' | 'center';
  aspect?: string;
  className?: string;
}

const LocationGallery = ({
  images,
  align = 'center',
  aspect = 'aspect-13/16',
  className,
}: LocationGalleryProps) => {
  const ref = useRef<Flicking>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null);

  const resetScrollInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (!ref.current) {
      return;
    }

    const interval = setInterval(() => {
      ref.current?.next().catch(() => void 0);
    }, 5000);

    setIntervalId(interval);
  };

  const next = async () => {
    ref.current?.next().catch(() => void 0);
    resetScrollInterval();
  };

  const prev = async () => {
    ref.current?.prev().catch(() => void 0);
    resetScrollInterval();
  };

  useEffect(() => {
    resetScrollInterval();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className={`relative ${className || 'mb-24'}`}>
      <ArrowButton
        variant="left"
        id="prev-gallery-btn"
        ariaLabel="Previous image"
        className="absolute z-30 -mt-6 left-6 lg:left-18 top-1/2"
        onClick={prev}
      />
      <ArrowButton
        variant="right"
        id="next-gallery-btn"
        ariaLabel="Next image"
        className="absolute z-30 -mt-6 right-6 lg:right-18 top-1/2"
        onClick={next}
      />
      <Flicking
        renderOnlyVisible={true}
        autoInit
        circular
        align={align}
        interruptable={false}
        ref={ref}
      >
        {[...images, ...images].map((image, index) => (
          <div className="px-3" key={`${image.localFile.url}-${index}`}>
            {image && (
              <Image
                image={getImage(image.localFile)}
                alt={image.altText}
                className={`w-auto h-[320px] lg:h-[552px] ${aspect}`}
              />
            )}
          </div>
        ))}
      </Flicking>
    </section>
  );
};

export default LocationGallery;
