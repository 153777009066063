import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import AboutLogosSection from '../components/organisms/AboutLogosSection';
import CateringInquiryFormSection from '../components/organisms/CateringInquiryFormSection';
import EventCardsSection from '../components/organisms/EventCardsSection';
import FeatureSection from '../components/organisms/FeatureSection';
import LocationGallery from '../components/organisms/LocationGallery';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Catering = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
      bannerSettings: {
        bannerSettings: { visible: bannerVisible },
      },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo
          post={page}
          scripts={[
            `(function() {
    var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id    = 'cio-forms-handler';
    t.setAttribute('data-site-id', 'd54f253ef97acf6a574e');
    t.setAttribute('data-base-url', 'https://customerioforms.com');

    t.src = 'https://customerioforms.com/assets/forms.js';

    s.parentNode.insertBefore(t, s);
  })();`,
          ]}
        />

        <div className="overflow-hidden">
          <div
            className={`${bannerVisible ? 'pt-48 md:pt-36' : 'pt-20'} -mx-4`}
          >
            <LocationGallery
              images={page.catering.heroGallery}
              align="prev"
              aspect="aspect-square"
              className="mb-0"
            />
          </div>
        </div>
        <EventCardsSection {...page.catering.eventsSection} />

        <CateringInquiryFormSection {...page.catering.inquiryForm} />
        <AboutLogosSection {...page.catering.cateringLogosSection} />
        <FeatureSection {...page.catering.featuresection} inverted />

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query CateringPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      catering {
        heroGallery {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED)
            }
          }
        }
        eventsSection {
          heading
          body
          cards {
            icon {
              altText
              localFile {
                url
              }
            }
            label
          }
          footerHeading
          footerBody
          brochures {
            city
            fileUrl
          }
        }
        inquiryForm {
          heading
          body
        }
        featuresection {
          heading
          body
          image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1472, placeholder: BLURRED)
              }
            }
            altText
          }
          buttonLabel
          buttonUrl
        }
        cateringLogosSection {
          heading
          logos {
            image {
              altText
              localFile {
                url
              }
            }
          }
        }
      }
    }
    wp {
      bannerSettings {
        bannerSettings {
          visible
        }
      }
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Catering;
