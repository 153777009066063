import axios from 'axios';
import enUS from 'date-fns/locale/en-US';
import { Formik } from 'formik';
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { object, string } from 'yup';
import BgDonut1 from '../atoms/BgDonut1';
import BgDonut4 from '../atoms/BgDonut4';
import CateringForm from '../molecules/CateringForm';

registerLocale('en-US', enUS);

interface CateringInquiryFormProps {
  heading: string;
  body: string;
}

const validationSchema = object().shape({
  name: string().required('Please enter your name'),
  phoneNumber: string().required('Please enter your phone number'),
  email: string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  date: string()
    .required("Please enter the date you're inquiring about")
    .test({
      test: (value) => moment(new Date(value)).isValid(),
      message: 'Please enter a valid date',
    }),
  nearestCity: string().required(
    'Please enter the nearest city to event location'
  ),
  description: string().required('Please enter the event details'),
});

const CateringInquiryFormSection = ({
  heading,
  body,
}: CateringInquiryFormProps) => {
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 4000], [400, -200], { clamp: false });
  const y2 = useTransform(scrollY, [0, 4000], [1200, 0], { clamp: false });

  const {
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
          city {
            cateringFormEmail
          }
        }
      }
    }
  `);

  const initialValues = {
    name: '',
    phoneNumber: '',
    email: '',
    date: '',
    nearestCity: '',
    description: '',
  };

  const handleSubmit = async (values) => {
    const formattedData = Object.entries(values).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [startCase(key)]:
          key === 'date' ? moment(value).format('MM/DD/YY') : value,
      }),
      {}
    );

    setError(null);
    setSuccess(false);

    const targetEmail =
      cities.find((city) => city.title === values.nearestCity)?.city
        .cateringFormEmail || '';

    try {
      await axios.post(
        'https://mailer.saltydonut.com/api/catering?email=' + targetEmail,
        { data: formattedData },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setSuccess(true);

      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: 'form_submission',
          formDetails: {
            name: values.name,
            phone: values.phoneNumber,
            email: values.email,
            date: values.date,
            description: values.description,
            city: values.nearestCity,
          },
        });
      }
    } catch (e) {
      setError('Form submission has failed.');
    }
  };

  return (
    <section className="relative py-16 overflow-hidden bg-linen lg:py-24">
      <BgDonut1
        className="absolute left-0 z-0 w-32 h-auto mt-32 opacity-50 md:mt-0"
        style={{ y: y1 }}
      />
      <BgDonut4
        className="absolute right-0 z-0 w-48 h-auto opacity-50 mt-96 md:mt-0 md:w-64"
        style={{ y: y2 }}
      />
      <div className="container z-10 justify-center space-y-8 lg:flex lg:space-y-0 transform-gpu">
        <div className="lg:w-5/12 xl:w-4/12 lg:pr-2">
          <h2 className="mb-4 u-h2">{heading}</h2>
          <div className="u-p" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div className="lg:w-6/12">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(props) => <CateringForm {...props} />}
          </Formik>
          <AnimatePresence>
            {error && (
              <motion.div
                className="text-red-accessibility pt-0.5 mt-6"
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
              >
                {error}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {success && (
              <motion.div
                key="success"
                className="flex items-center mt-6 space-x-6 text-2xl font-bold uppercase font-heading text-navy-blue"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div>
                  Thank you for submitting your catering inquiry. We will be in
                  touch shortly.
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default CateringInquiryFormSection;
