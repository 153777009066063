import Flicking from '@egjs/react-flicking';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../atoms/Button';
import ArrowButton from '../molecules/ArrowButton';

interface AboutLogosSectionProps {
  heading: string;
  logos: Array<{
    image: {
      altText: string;
      localFile: {
        url: string;
      };
    };
  }>;
  ctaHeading?: string;
  buttonLabel?: string;
  buttonUrl?: string;
}

const AboutLogosSection = ({
  heading,
  ctaHeading,
  logos,
  buttonLabel,
  buttonUrl,
}: AboutLogosSectionProps) => {
  const ref = useRef<Flicking>(null);

  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null);

  const resetScrollInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (!ref.current) {
      return;
    }

    const interval = setInterval(() => {
      ref.current?.next().catch(() => void 0);
    }, 5000);

    setIntervalId(interval);
  };

  const next = async () => {
    ref.current?.next().catch(() => void 0);
    resetScrollInterval();
  };

  const prev = async () => {
    ref.current?.prev().catch(() => void 0);
    resetScrollInterval();
  };

  useEffect(() => {
    resetScrollInterval();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="py-16 text-center lg:py-24 text-navy-blue">
      <h2 className="mb-8 u-h2">{heading}</h2>

      <div className="container relative flex items-center mb-8 md:px-16">
        <ArrowButton
          variant="left"
          id="prev-image-btn"
          ariaLabel="Previous image"
          color="linen"
          className="absolute z-20 -mt-6 left-2 md:left-0 top-1/2"
          onClick={prev}
        />
        <Flicking
          renderOnlyVisible={true}
          autoInit
          circular
          align="center"
          interruptable={false}
          ref={ref}
        >
          {[...logos, ...logos].map(({ image }, index) => (
            <div
              key={`${image.localFile.url}${index}`}
              className="flex justify-center w-screen h-16 sm:h-20 sm:w-32 mx-9"
            >
              <img
                src={image.localFile.url}
                alt={image.altText}
                className="object-contain w-auto h-full mx-auto"
              />
            </div>
          ))}
        </Flicking>
        <ArrowButton
          variant="right"
          id="next-image-btn"
          ariaLabel="Next image"
          color="linen"
          className="absolute z-20 -mt-6 right-2 md:right-0 top-1/2"
          onClick={next}
        />
      </div>
      {ctaHeading && (
        <>
          <h3 className="px-5 mb-6 u-p">{ctaHeading}</h3>
          <Button to={buttonUrl} variant="default">
            {buttonLabel}
          </Button>
        </>
      )}
    </section>
  );
};

export default AboutLogosSection;
