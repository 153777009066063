import { Form, FormikProps } from 'formik';
import React from 'react';
import Button from '../atoms/Button';
import CityDropdownInput from '../atoms/CityDropdownInput';
import DateInput from '../atoms/DateInput';
import FormInput from '../atoms/FormInput';

const CateringForm = (props: FormikProps<any>) => (
  <Form className="space-y-6">
    <div>
      <FormInput name="name" label="Name" />
    </div>
    <div>
      <FormInput name="phoneNumber" type="tel" label="Phone Number" />
    </div>
    <div>
      <FormInput
        id="emailAddress"
        name="email"
        type="email"
        label="Email Address"
      />
    </div>
    <div>
      <DateInput
        name="date"
        label="What date are you inquiring about?"
        placeholder="MM/DD/YY"
      />
    </div>
    <div>
      <CityDropdownInput
        name="nearestCity"
        label="Nearest City to Event Location"
        placeholder="Please choose"
      />
    </div>
    <div>
      <FormInput
        name="description"
        label="What can we get going for you?"
        isTextArea
      />
    </div>
    <div className="pt-2">
      <Button type="submit" variant="default" disabled={props.isSubmitting}>
        {props.isSubmitting ? 'Submitting...' : 'Send'}
      </Button>
    </div>
  </Form>
);

export default CateringForm;
